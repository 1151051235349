@import 'header-search';
@import 'header-shopping-cart';

.ext-title {
    font-size: 14px;
    color: #fff;
}

header.fixed + main {
    margin-top: 50px;
}

body {
    &.headerless > main {
        padding-top: 0;
    }

    &.lock {
        & > header{
            position: fixed;
            bottom: 0;
            & + main {
                margin-top: 50px;
            }
        }
        .overlay {
            background-color: rgba(0, 0, 0, 0.75);
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -1;
        }
    }

    & > header {
        top: 0;
        left: 0;
        overflow: auto;
        right: 0;
        z-index: 99;

        nav {
            background-color: #95c731;
            height: 50px;
            position: relative;
            overflow: hidden;

            @include context("body", "body.lock") {
                overflow: visible;
            }

            .vo-container {
                position: relative;
            }

            .brand {
                margin-top: 5px;
                margin-bottom: 5px;
                min-height: 40px;
            }

            img.brand-logo {
                /*width: 84px;*/
                height: 40px;
            }

            .lvl-0, .lvl-1, .lvl-2 {
                border-top: solid #5d5d5d 1px;
                &.open .arrow {
                    color: #fff;
                }
            }

            .lvl-0 {
                padding-left: 15px;
                &.first {
                    border-top: none;
                }
            }

            .lvl-1 {
                padding-left: 45px;
            }

            .lvl-2, .lvl-3 {
                padding-left: 75px;
                background-color: #3d3d3d;
            }

            .single-field {
                border: none;
                .field {
                    height: auto;
                    padding: 0 0 17px 0;
                    color: #fff;
                    position: relative;
                    .cta.single-field {
                        position: relative;
                        width: 600px;
                        height: 50px;
                        margin-bottom: 8px;
                        input[type=text] {
                            @include placeholder {
                                text-align:center;
                                color: #a4a4a4;
                                text-transform: uppercase;
                            }
                            padding: 0 40px 0 10px;
                            border: none;
                            font-size: 11px;
                            color: #a4a4a4;
                            font-weight: 600;
                            width: 100%;
                            height: 100%;
                            border-radius: 0;
                        }
                        label {
                            position: absolute;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            height: 100%;
                            color: #fff;
                            width: 30px;
                            background-color: #fda100;
                            border: none;
                            span {
                                font-size: 50px;
                                &:before {
                                    right: -10px;
                                    top: 0;
                                    position: absolute;
                                }
                            }
                        }
                    }
                }
            }

            .option {
                color: #fff;
                min-height: 50px;
                height: auto;
                position: relative;


                .name {
                    display: block;
                    height: 100%;
                    padding-top: 14px;
                    line-height: 21px;
                    &.orange {
                        color: #fda100;
                    }
                    &.active{
                        color: #FFFFFF;
                    }

                    a{
                        display: block;
                        padding: 15px 0;
                        margin-top: -15px;
                        &:focus {
                            text-decoration: none;
                            color: inherit;
                        }
                        &.active{
                            color: #FFFFFF;
                        }
                    }
                }

                .arrow {
                    font-size: 50px;
                    position: absolute;
                    right: 15px;
                    top: 0;
                }

                .icon-U {
                    color: #95c731;
                    font-size: 50px;
                    position: absolute;
                    left: -42px;
                    top: 0;
                }

                a {
                    &.orange {
                        color: #fda100 !important;
                    }
                }
            }

            %header-option-name-common {
                display: block;
                height: 100%;
                padding-top: 14px;
            }

            .title > .option > .name {
                @extend .ext-title;

                font-weight: 700;
                text-transform: uppercase;
            }

            .subtitle {
                position: relative;

                &.open {
                    border-bottom: solid #5d5d5d 1px;
                }

                & > .option > .name {
                    @extend .ext-title;
                    min-height: 50px;
                }
            }

            .item {
                border-top: none;
                &.sep {
                    border-top: solid #5d5d5d 1px;
                }
                & > .option > a {
                    min-height: 50px;
                    font-size: 14px;
                    color: #fff;
                    text-decoration: none;
                }
            }

            ul.links {
                height: 0;
                background-color: #262626;
                z-index: 999;
                margin: 0 auto;
                list-style: none;
                font-size: 15px;
                width: 768px;
                overflow: auto;
                max-height: 100%;
                z-index: -1;
                margin-left: -15px;

                .menu {
                    &.author {
                        padding: 0 45px 35px 45px;
                        .col-1 {
                            display: inline-block;
                            margin-right: 15px;
                            vertical-align: top;
                            width: 255px;
                            float: left;
                        }
                        .col-2 {
                            display: inline-block;
                            margin-right: 30px;
                            vertical-align: top;
                            width: 378px;
                            float: left;
                        }
                        .col-3 {
                            float: right;
                            margin: 20px 15px 0;
                            vertical-align: bottom;
                            width: 160px;

                            @extend %link-no-effects;
                            a{
                                text-decoration: none;
                            }
                        }

                        h4 {
                            font-size: 14px;
                            font-weight: bold;
                            color: #95c731;
                            text-transform: uppercase;
                        }

                        h5 {
                            @extend .text-large;
                            color: #95c731;
                            margin-bottom: 15px;
                            margin-top: 30px;
                        }

                        .content {
                            @extend .text-regular;
                            color: #9d9d9d;
                            margin-bottom: 20px;
                        }

                        .more {
                            @extend .text-regular;
                            text-decoration: underline;
                            color: #9d9d9d;
                            text-transform: none;
                        }

                        .author-name-link {
                            text-decoration: none;
                        }

                        .picture {
                            height: 300px;
                            margin-top: 15px;
                            margin-right: 15px;
                            width: 255px;
                            background-size: cover;
                        }

                        .button {
                            @include button-type-b(#95c731);
                            font-weight: 600;
                            position: absolute;
                            bottom: 0;
                            right: -15px;
                            padding: 12px 20px;
                            & > .icon-C {
                                font-size: 50px;
                                position: absolute;
                                top: -5px;
                            }
                        }

                    }

                    &.layout-c {
                        padding: 20px 0 35px 45px;
                        h4 {
                            margin-bottom: 5px;
                            font-weight: 700;
                            font-size: 14px;
                            text-transform: uppercase;
                            color: #95c731;
                        }

                        h5 {
                            margin-bottom: 10px;
                            font-size: 25px;
                            line-height: 28px;
                            color: #95c731;
                        }

                        .col-1 {
                            display: inline-block;
                            width: 255px;
                            margin-right: 15px;
                        }

                        .col-2 {
                            display: inline-block;
                            width: 370px;
                            vertical-align: top;
                            padding-top: 15px;

                            .content, a {
                                font-size: 14px;
                                text-align: left;
                                line-height: 18px;
                                color: #9d9d9d;
                            }

                            .content {
                                margin-bottom: 10px;
                            }
                        }

                        .col-3 {
                            position: absolute;
                            bottom: 0;
                            width: 370px;
                            left: 270px;
                            text-align: right;

                            button {
                                @include button-type-b(#95c731);
                                font-weight: 600;
                                & > .icon-C {
                                    font-size: 50px;
                                    position: absolute;
                                    top: -4px;
                                }

                            }
                        }
                    }
                }

                .vo-container {
                    width: 100%;
                }

                li > span {
                    color: #fff;
                    font-size: 15px;
                    text-decoration: none;
                }

                li.title > span {
                    color: #fff;
                    font-weight: 700;
                    text-transform: uppercase;
                    font-size: 14px;
                }

                li.item.infopedia-item {
                    border-top: solid #5d5d5d 1px;
                }

                & > li {
                    margin-right: 15px;
                    margin: 0 auto;
                    &.last {
                        margin-right: 0;
                    }
                }
            }

            ul.navbar-misc {
                list-style: none;
                height: 50px;
                overflow: hidden;
                position: absolute;
                top: 0;
                right: 0;
                line-height: 50px;
                padding: 7px 0;
                & > li {
                    margin-top: -6px;
                    float: left;
                    height: 50px;
                    color: #FFFFFF;
                    font-size: 50px;
                    padding: 0;
                    cursor: pointer;
                    position: relative;
                    &.empty-shopping-cart .total-cart-items{
                        display:none;
                    }
                    & > a, div {
                        text-decoration: none;
                    }

                    .total-cart-items{
                        font-size: 12px;
                        font-weight: 700;
                        text-align: center;
                        color: #95c731;
                        border-radius: 21px;
                        border-color: #95c731;						
                        background: #FFFFFF;
                        width: 21px;
                        height: 21px;
                        line-height: 21px;
                        position: absolute;
                        right: calc(50% - 21px);
                        top: 5px;
                    }
                }
            }
        }

        &.fixed{
            position: fixed;

            ul.links {
                //position: fixed;
                margin-left: -15px;
                margin-right: -15px;
                //padding-top: 50px;
            }

            ul.navbar-misc {
                //position: relative;
            }
        }
    }
}
